import { toast } from 'react-toastify';
import { call, put, takeLatest, all } from 'redux-saga/effects';

import * as Actions from './actions';
import * as types from './types';
import api from '~/services/api';
import history from '~/services/history';

export function* createReward({ payload }) {
  try {
    const { imageUrl, coinType, name, costValue, description } = payload;
    yield call(api.post, '/api/v1/admin/rewards', {
      imageUrl,
      coinType,
      name,
      costValue,
      description,
    });

    yield put(Actions.createRewardSuccess());

    history.go(0);
    toast.success('Recompensa cadastrada com sucesso!');
  } catch (err) {
    toast.error(
      'Ops... parece que ocorreu um erro ao criar a recompensa. Tente novamente mais tarde.'
    );
    yield put(Actions.failure());
  }
}

export function* updateReward({ payload }) {
  try {
    const { imageUrl, coinType, name, costValue, description, id } = payload;
    yield call(api.put, `/api/v1/admin/rewards/${id}`, {
      imageUrl,
      coinType,
      name,
      costValue,
      description,
    });

    yield put(Actions.updateRewardSuccess());

    history.go(0);
    toast.success('Recompensa atualizada com sucesso!');
  } catch (err) {
    toast.error(
      'Ops... parece que ocorreu um erro ao atualizar a recompensa. Tente novamente mais tarde.'
    );
    yield put(Actions.failure());
  }
}

export default all([
  takeLatest(types.CREATE_REWARD_REQUEST, createReward),
  takeLatest(types.UPDATE_REWARD_REQUEST, updateReward),
]);
