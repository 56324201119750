import React, { Suspense } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter, Router } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import Routes from './routes';
import history from './services/history';
import { store } from './store';

function App() {
  return (
    <Provider store={store}>
      <Suspense fallback={<div className="loading" />}>
        <BrowserRouter>
          <Router history={history}>
            <Routes />
            <ToastContainer />
          </Router>
        </BrowserRouter>
      </Suspense>
    </Provider>
  );
}

export default App;
