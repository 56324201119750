import { IoHomeOutline, IoPeople } from 'react-icons/io5';

import { appRoot } from './defaultValues';

const data = [
  {
    id: 'home',
    iconComponent: IoHomeOutline,
    label: 'Início',
    to: `${appRoot}/`,
  },
  {
    id: 'users',
    iconComponent: IoPeople,
    label: 'Pacientes',
    to: `${appRoot}/pacientes`,
    roles: [],
  },
  {
    id: 'rewards',
    iconComponent: IoPeople,
    label: 'Recompensas',
    to: `${appRoot}/recompensas`,
    roles: ['admin'],
  },
  {
    id: 'rescues',
    iconComponent: IoPeople,
    label: 'Resgates',
    to: `${appRoot}/resgates`,
    roles: ['admin'],
  },
];

export default data;
