import React from 'react';
import { IoPeople } from 'react-icons/io5';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import {
  UncontrolledDropdown,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
} from 'reactstrap';

import { MobileMenuIcon, MenuIcon } from '~/components/svg';
import { appRoot } from '~/constants/defaultValues';
import { signOut } from '~/store/modules/auth/actions';
import {
  setContainerClassnames,
  clickOnMobileMenu,
} from '~/store/modules/menu/actions';

const TopNav = () => {
  const { profile, isHealthProvider } = useSelector((state) => state.user);

  const { containerClassnames, menuClickCount, selectedMenuHasSubItems } =
    useSelector((state) => state.menu);

  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(signOut());
  };

  const menuButtonClick = (e, _clickCount, _conClassnames) => {
    e.preventDefault();

    setTimeout(() => {
      const event = document.createEvent('HTMLEvents');
      event.initEvent('resize', false, false);
      window.dispatchEvent(event);
    }, 350);
    dispatch(
      setContainerClassnames(
        _clickCount + 1,
        _conClassnames,
        selectedMenuHasSubItems
      )
    );
  };

  const mobileMenuButtonClick = (e, _containerClassnames) => {
    e.preventDefault();
    dispatch(clickOnMobileMenu(_containerClassnames));
  };

  return (
    <nav className="navbar fixed-top">
      <div className="d-flex align-items-center navbar-left">
        <NavLink
          to="#"
          location={{}}
          className="menu-button d-none d-md-block"
          onClick={(e) =>
            menuButtonClick(e, menuClickCount, containerClassnames)
          }>
          <MenuIcon />
        </NavLink>
        <NavLink
          to="#"
          location={{}}
          className="menu-button-mobile d-xs-block d-sm-block d-md-none"
          onClick={(e) => mobileMenuButtonClick(e, containerClassnames)}>
          <MobileMenuIcon />
        </NavLink>

        <NavLink className="navbar-logo" to={appRoot}>
          <span className="logo d-none d-xs-block" />
          <span className="logo-mobile d-block d-xs-none" />
        </NavLink>
      </div>

      <div className="navbar-right">
        <div className="user d-inline-block">
          <UncontrolledDropdown className="dropdown-menu-right">
            <DropdownToggle className="p-0" color="empty">
              <div className="d-flex align-items-center">
                <div className="d-flex flex-column align-items-end">
                  <span className="name mr-2">
                    {isHealthProvider
                      ? `${profile.pole} - ${profile.village}`
                      : profile.name}
                  </span>
                </div>
                <span className="rounded-circle bg-light">
                  <IoPeople size={24} />
                </span>
              </div>
            </DropdownToggle>
            <DropdownMenu className="mt-3" right>
              {/* <DropdownItem divider /> */}
              <DropdownItem onClick={() => handleLogout()}>Sair</DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>
      </div>
    </nav>
  );
};

export default TopNav;
