import produce from 'immer';

import * as types from './types';

const INITIAL_STATE = {
  loading: false,
};

const reducer = (state = INITIAL_STATE, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case types.CREATE_REWARD_REQUEST: {
        draft.loading = true;

        break;
      }
      case types.CREATE_REWARD_SUCCESS: {
        draft.loading = false;

        break;
      }

      case types.UPDATE_REWARD_REQUEST: {
        draft.loading = true;

        break;
      }
      case types.UPDATE_REWARD_SUCCESS: {
        draft.loading = false;

        break;
      }

      case types.FAILURE: {
        draft.loading = false;

        break;
      }

      default:
    }
  });
export default reducer;
