import produce from 'immer';

const INITIAL_STATE = {
  profile: null,
  isHealthProvider: false,
  isAdmin: false,
};

const reducer = (state = INITIAL_STATE, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case '@auth/SIGN_IN_SUCCESS': {
        draft.profile = action.payload.user;
        draft.isHealthProvider = action.payload.isHealthProvider;
        draft.isAdmin = action.payload.isAdmin;

        break;
      }

      case '@auth/SIGN_OUT': {
        draft.profile = null;
        draft.isHealthProvider = false;
        draft.isAdmin = false;

        break;
      }

      default:
    }
  });
export default reducer;
