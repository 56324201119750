/* eslint-disable react/prop-types */
import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Sidebar from '~/containers/navs/Sidebar';
import TopNav from '~/containers/navs/Topnav';

const AppLayout = ({ children }) => {
  const history = useHistory();
  const { containerClassnames } = useSelector((state) => state.menu);

  return (
    <div id="app-container" className={containerClassnames}>
      <TopNav history={history} />
      <Sidebar />
      <main>
        <div className="container-fluid">{children}</div>
      </main>
    </div>
  );
};

export default AppLayout;
