import { toast } from 'react-toastify';
import { call, put, takeLatest, all } from 'redux-saga/effects';

import * as AuthActions from './actions';
import * as types from './types';
import { appRoot } from '~/constants/defaultValues';
import api from '~/services/api';
import history from '~/services/history';

export function* signIn({ payload }) {
  try {
    const { userField, password, isHealthProvider, isAdmin } = payload;

    let urlPrefix = isHealthProvider ? 'health_providers' : 'agents';
    urlPrefix = isAdmin ? 'admin' : urlPrefix;
    let fieldName = isHealthProvider ? 'username' : 'agent';
    fieldName = isAdmin ? 'email' : fieldName;
    const { data } = yield call(api.post, `${urlPrefix}/signIn`, {
      [fieldName]: userField,
      password,
    });

    api.defaults.headers.Authorization = `Bearer ${data.token}`;
    yield put(
      AuthActions.signInSuccess({
        user: data.agent ?? data.healthProvider ?? data.admin,
        token: data.token,
        isHealthProvider,
        isAdmin,
      })
    );

    history.push(`${appRoot}/`);
  } catch (err) {
    switch (err?.response?.status) {
      case 401:
        toast.error(
          'Usuário Inativo',
          'Aguarde o administrador da Unidade de Saúde fazer a ativação e tente novamente.'
        );
        break;
      case 404:
        toast.error(
          'Credenciais Inválidas\nNenhuma conta associada a esse e-mail/usuário e senha foi encontrada.'
        );
        break;
      default:
        toast.error(
          'CPF/Email e/ou senha informados estão incorretos ou inválidos.'
        );
        break;
    }

    yield put(AuthActions.failure());
  }
}

export function setToken({ payload }) {
  if (!payload) {
    return;
  }

  const { token } = payload.auth;

  if (token) {
    api.defaults.headers.Authorization = `Bearer ${token}`;
  }
}

export function signOut() {
  api.defaults.headers.Authorization = null;
  history.push('/login');
}

export default all([
  takeLatest('persist/REHYDRATE', setToken),
  takeLatest(types.SIGN_IN_REQUEST, signIn),
  takeLatest(types.SIGN_OUT, signOut),
]);
