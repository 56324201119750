import * as types from './types';

export function signInRequest({
  userField,
  password,
  isHealthProvider,
  isAdmin,
}) {
  return {
    type: types.SIGN_IN_REQUEST,
    payload: { userField, password, isHealthProvider, isAdmin },
  };
}

export function signInSuccess({ token, isHealthProvider, user, isAdmin }) {
  return {
    type: types.SIGN_IN_SUCCESS,
    payload: { token, isHealthProvider, user, isAdmin },
  };
}

export function signOut() {
  return {
    type: types.SIGN_OUT,
    payload: {},
  };
}

export function failure() {
  return {
    type: types.FAILURE,
    payload: {},
  };
}
