import { combineReducers } from 'redux';

import auth from './auth';
import menu from './menu';
import quiz from './quiz';
import reward from './reward';
import user from './user';

const reducers = combineReducers({
  menu,
  auth,
  user,
  quiz,
  reward,
});

export default reducers;
