import produce from 'immer';

const INITIAL_STATE = {
  loading: false,
  signed: false,
  token: null,
};

const reducer = (state = INITIAL_STATE, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case '@auth/SIGN_IN_REQUEST': {
        draft.loading = true;

        break;
      }
      case '@auth/SIGN_IN_SUCCESS': {
        draft.loading = false;
        draft.signed = true;
        draft.token = action.payload.token;

        break;
      }

      case '@auth/SIGN_OUT': {
        draft.signed = false;
        draft.token = null;
        draft.refreshToken = null;

        break;
      }
      case '@auth/FAILURE': {
        draft.loading = false;
        draft.signed = false;
        draft.token = null;
        draft.refreshToken = null;

        break;
      }

      default:
    }
  });
export default reducer;
