import { toast } from 'react-toastify';
import { call, put, takeLatest, all } from 'redux-saga/effects';

import * as Actions from './actions';
import * as types from './types';
import { appRoot } from '~/constants/defaultValues';
import api from '~/services/api';
import history from '~/services/history';

export function* createQuizQuestion({ payload }) {
  try {
    const { question, options, correctOption } = payload;
    yield call(api.post, '/api/v1/admin/survey_questions/Diabetes', {
      question,
      options,
      type: 'select',
      correctOptionIndex: correctOption,
    });

    yield put(Actions.createQuestionSuccess());

    history.go(0);
    toast.success('Pergunta cadastrada com sucesso!');
  } catch (err) {
    toast.error(
      'Ops... parece que ocorreu um erro ao criar a pergunta. Tente novamente mais tarde.'
    );
    yield put(Actions.failure());
  }
}

export default all([
  takeLatest(types.CREATE_QUESTION_REQUEST, createQuizQuestion),
]);
