import React from 'react';
import { Switch, Redirect } from 'react-router-dom';

import Route from './Route';
import { appRoot } from '~/constants/defaultValues';

const Login = React.lazy(() => import('../pages/Login'));

const App = React.lazy(() => import('../pages/app'));
const AdminApp = React.lazy(() => import('../pages/admin'));
const HealthProviderApp = React.lazy(() =>
  import('../pages/healthProviderApp')
);

export default function Routes() {
  return (
    <Switch>
      <Redirect exact from="/" to="/login" />
      <Route path="/login" component={Login} />
      <Route
        path={appRoot}
        isPrivate
        component={App}
        adminComponent={AdminApp}
        healthProviderComponent={HealthProviderApp}
      />
    </Switch>
  );
}
