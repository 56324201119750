import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';

import { appRoot } from '~/constants/defaultValues';
import AppLayout from '~/layout/AppLayout';
import DefaultLayout from '~/layout/DefaultLayout';

export default function RouteWrapper({
  component: Component,
  healthProviderComponent: HealthProviderComponent,
  adminComponent: AdminComponent,
  isPrivate,
  ...rest
}) {
  const { signed } = useSelector((state) => state.auth);
  const { isHealthProvider, isAdmin } = useSelector((state) => state.user);

  const Layout = signed ? AppLayout : DefaultLayout;

  if (isPrivate && !signed) {
    return <Redirect to="/login" />;
  }

  if (!isPrivate && signed) {
    return <Redirect to={appRoot} />;
  }

  const RenderComponent = isHealthProvider
    ? HealthProviderComponent
    : Component;

  return (
    <Route
      {...rest}
      render={(props) => (
        <Layout>
          {isAdmin ? (
            <AdminComponent {...props} />
          ) : (
            <RenderComponent {...props} />
          )}
        </Layout>
      )}
    />
  );
}

RouteWrapper.propTypes = {
  component: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.func,
    PropTypes.object,
  ]).isRequired,
  healthProviderComponent: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.func,
    PropTypes.object,
  ]),
  adminComponent: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.func,
    PropTypes.object,
  ]),
  isPrivate: PropTypes.bool,
};

RouteWrapper.defaultProps = {
  isPrivate: false,
  healthProviderComponent: null,
  adminComponent: null,
};
