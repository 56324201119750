import * as types from './types';

export function createRewardRequest({
  imageUrl,
  coinType,
  name,
  costValue,
  description,
}) {
  return {
    type: types.CREATE_REWARD_REQUEST,
    payload: { imageUrl, coinType, name, costValue, description },
  };
}

export function createRewardSuccess() {
  return {
    type: types.CREATE_REWARD_SUCCESS,
    payload: {},
  };
}

export function updateRewardRequest({
  id,
  imageUrl,
  coinType,
  name,
  costValue,
  description,
}) {
  return {
    type: types.UPDATE_REWARD_REQUEST,
    payload: { imageUrl, coinType, name, costValue, description, id },
  };
}

export function updateRewardSuccess() {
  return {
    type: types.UPDATE_REWARD_SUCCESS,
    payload: {},
  };
}

export function failure() {
  return {
    type: types.FAILURE,
    payload: {},
  };
}
