import * as types from './types';

export function createQuestionRequest({ question, options, correctOption }) {
  return {
    type: types.CREATE_QUESTION_REQUEST,
    payload: { question, options, correctOption },
  };
}

export function createQuestionSuccess() {
  return {
    type: types.CREATE_QUESTION_SUCCESS,
    payload: {},
  };
}

export function failure() {
  return {
    type: types.FAILURE,
    payload: {},
  };
}
